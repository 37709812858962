import { IndexPageConfig } from "../../../gatsby/config/create-pages/types"
import { PathPrefix } from "../../core/constants"

export const newsIndexConfig: IndexPageConfig = {
  templatePath: "./src/features/posts/templates/news-template.tsx",
  pagePath: PathPrefix.News,
  searchConfig: { filters: `type:post` },
  attributes: ["type", "time", "title", "url"],
  indexes: ["featured", "newest", "oldest", "alphabetical"],
  filters: [
    {
      attribute: "fields.primaryTopic.fields.name",
      title: "search-filter-topics"
    },
    {
      attribute: "fields.teachers.fields.shortName",
      title: "search-filter-teachers"
    },
    {
      attribute: "fields.primaryScriptureReference.start.book",
      title: "search-filter-scripture"
    }
  ],
  defaultSearchIndex: "newest"
}
