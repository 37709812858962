import React, { FunctionComponent } from "react"
import { PageProps } from "@src-types/page"
import { useIndexPageData } from "@hooks/use-index-page-data"
import { PostPage } from "@features/posts/components/post-page/post-page"
import { Page } from "@components/page/page"
import { newsIndexConfig } from "@features/posts/news-index-config"
import { PageBreadcrumbs } from "@core/breadcrumbs"

export const News: FunctionComponent<PageProps> = ({ ...props }) => {
  const { header, featuredResources, metadata } = useIndexPageData("news")

  return (
    <Page
      {...props}
      metadata={metadata}
      pageOptions={{
        headerVariant: "cream",
        breadcrumbs: PageBreadcrumbs.News,
        defaultSearchIndex: newsIndexConfig.defaultSearchIndex
      }}
    >
      <PostPage
        featuredLabel="featured-news"
        featuredList={featuredResources}
        indexConfig={newsIndexConfig}
        pageHeader={header}
        pageKey="news"
        disableFilters={true}
      />
    </Page>
  )
}

export default News
